import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import { Link } from "gatsby"
import {Section, Container, SectionTitle, ListPointer, ListItemPointer, SectionPageTitle } from "../components/Section"

const SitemapGrid = styled.div`
  position: relative;
  @media(min-width:576px){
    column-fill: balance;
    column-count: 2;
  }
  @media(min-width:1200px){
    column-fill: balance;
    column-count: 3;
  }
  & a {
    display: inline-block;
    font-size: 16px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.7);
    &:hover {
      color: #161420;
    }
  }
`
const ListPointers = styled(ListPointer)`
  margin:0 0 40px 0;
  li {
    &:before{
      vertical-align: top;
    }
    + li {
      margin-top: 0;
    }
  }
`

// const SitemapItem = styled.div`
//   position:relative;
//   width:100%;
//   padding:0 15px;
//   margin-bottom: 30px;
//   @media(min-width:576px){
//     flex: 0 0 50%;
//     max-width: 50%;
//   }
//   @media(min-width:992px){
//     flex: 0 0 33.333333%;
//     max-width: 33.333333%;
//   }
//   & a {
//     display: inline-block;
//     line-height: 30px;
//     color: rgba(0, 0, 0, 0.7);
//     &:hover {
//       color: #161420;
//     }
//   }
// `
const SiteMapTitle = styled(SectionTitle)`
  & a {
    color: #161420;
    &:hover {
      color: #fc0002;
    }
  }
`
const SiteMapPage = ({ location }) => (
  <Layout location={location}>
    <Seo title="Sitemap - Engineered Metal Building" description="Explore Super Steel Structures' site map to effortlessly navigate through our offerings, from steel building solutions to customer testimonials. Find what you need easily!" />    
    <Section ept="160px" epb="60px" xpt="140px" xpb="40px" pt="90px" pb="30px" bgColor="#fff" className="section-url">
      <Container>
        <SectionPageTitle mb="40px">Sitemap</SectionPageTitle>
        <SitemapGrid>
          {/* <SitemapItem> */}

            <SiteMapTitle className="h5">Know-How</SiteMapTitle>
            <ListPointers>
              <ListItemPointer><Link to='/shop-metal-buildings'>Shop Now</Link></ListItemPointer>
              <ListItemPointer><Link to='/metal-buildings'>Metal Buildings</Link></ListItemPointer>
              <ListItemPointer><Link to='/about-us'>About Us</Link></ListItemPointer>
              <ListItemPointer><Link to='/contact-us'>Contact Us </Link></ListItemPointer>
            </ListPointers>

            <SiteMapTitle className="h5">Quick Links</SiteMapTitle>
            <ListPointers>
              <ListItemPointer><Link to="/rent-to-own-metal-buildings">RTO</Link></ListItemPointer>
              <ListItemPointer><Link to="/financing">Financing</Link></ListItemPointer>
              <ListItemPointer><a href="https://engineeredmetalbuildings.sensei3d.com/" target="_blank" rel="noreferrer" aria-label="Design in 3D" >Design in 3D</a></ListItemPointer>                      
              <ListItemPointer><Link to="/privacy-policy">Privacy Policy</Link></ListItemPointer>
              <ListItemPointer><Link to="/faqs">FAQs</Link></ListItemPointer>
              <ListItemPointer><Link to="/terms-and-conditions">Terms & Conditions</Link></ListItemPointer>
            </ListPointers>

            <SiteMapTitle className="h5">OUR MANUFACTURES</SiteMapTitle>
            <ListPointers>
              <ListItemPointer><Link to='/coast-to-coast-carports'>Coast to Coast Carports</Link></ListItemPointer>
              <ListItemPointer><Link to='/american-steel-carports-inc'>American Steel Carports , Inc.</Link></ListItemPointer>
              <ListItemPointer><Link to='/safeguard-metal-buildings'>Safeguard Metal Buildings</Link></ListItemPointer>
              <ListItemPointer><Link to='/star-buildings-and-carports'>Star Buildings and Carports</Link></ListItemPointer>
              <ListItemPointer><Link to='/eagle-carports'>Eagle Carports</Link></ListItemPointer>
              <ListItemPointer><Link to='/steel-building-and-structures'>Steel Building and Structures</Link></ListItemPointer>
              <ListItemPointer><Link to='/california-all-steel-carports'>California All Steel Carports</Link></ListItemPointer>
              <ListItemPointer><Link to='/american-carports'>American Carports</Link></ListItemPointer>
              <ListItemPointer><Link to='/tnt-carports-inc'>TNT Carports, Inc.</Link></ListItemPointer>
              <ListItemPointer><Link to='/bmb-metal-buildings'>BMB Metal Buildings</Link></ListItemPointer>
              <ListItemPointer><Link to='/tri-state-carports'>Tri State Carports</Link></ListItemPointer>
              <ListItemPointer><Link to='/panhandle-metals'>Panhandle Metals</Link></ListItemPointer>
            </ListPointers>
            
            <SiteMapTitle className="h5">PROJECTS</SiteMapTitle>
            <ListPointers>
              <ListItemPointer><Link to='/30x50x14-a-frame-vertical-roof-building'>30x50x14 A-Frame Vertical Roof Building</Link></ListItemPointer>
              <ListItemPointer><Link to='/30x50x13-a-frame-with-lean-to'>30x50x13 A-Frame With Lean-To</Link></ListItemPointer>
              <ListItemPointer><Link to='/30x40x13-a-frame-vertical-garage'>30x40x13 A-Frame Vertical Garage</Link></ListItemPointer>
              <ListItemPointer><Link to='/30x25x12-colonial-style-barn'>30x25x12 Colonial Style Barn</Link></ListItemPointer>
              <ListItemPointer><Link to='/20x100x12-a-frame-storage-unit'>20x100x12 A-Frame Storage Unit</Link></ListItemPointer>
              <ListItemPointer><Link to='/20x60x9-a-frame-storage-unit'>20x60x9 A-Frame Storage Unit</Link></ListItemPointer>
              <ListItemPointer><Link to='/20x20x10-a-frame-vertical-roof-garage'>20x20x10 A-Frame Vertical Roof Garage</Link></ListItemPointer>
              <ListItemPointer><Link to='/32x30x12-a-frame-colonial-barn'>32x30x12 A-Frame Colonial Barn</Link></ListItemPointer>
              <ListItemPointer><Link to='/30x70x10-a-frame-vertical-garage'>30x70x10 A-Frame Vertical Garage</Link></ListItemPointer>
              <ListItemPointer><Link to='/32x40x12-a-frame-warehouse'>32x40x12 A-Frame Warehouse</Link></ListItemPointer>
              <ListItemPointer><Link to='/36x20x12-a-frame-colonial-barn-all-vertical'>36x20x12 A Frame Colonial Barn All Vertical</Link></ListItemPointer>
              <ListItemPointer><Link to='/36x40x20-a-frame-vertical-roof-warehouse'>36x40x20 A-Frame Vertical Roof Warehouse</Link></ListItemPointer>
              <ListItemPointer><Link to='/40x60x16-commercial-clear-span-all-vertical'>40x60x16 Commercial Clear Span All Vertical</Link></ListItemPointer>
              <ListItemPointer><Link to='/44x40x14-a-frame-vertical-roof-barn'>44x40x14 A-Frame Vertical Roof Barn</Link></ListItemPointer>
              <ListItemPointer><Link to='/48x30x12-10-gambrel-barn'>48x30x12-10 Gambrel Barn</Link></ListItemPointer>
              <ListItemPointer><Link to='/50x25x16-12-10-all-vertical-barn'>50x25x16-12-10 All Vertical Barn</Link></ListItemPointer>
              <ListItemPointer><Link to='/50x50x18-all-vertical-clear-span'>50x50x18 All Vertical Clear Span</Link></ListItemPointer>
              <ListItemPointer><Link to='/60x50x12-warehouse'>60x50x12 Warehouse</Link></ListItemPointer>
              <ListItemPointer><Link to='/50x150x16-a-frame-workshop'>50x150x16 A-Frame Workshop</Link></ListItemPointer>
              <ListItemPointer><Link to='/70x30x13-a-frame-vertical-roof-barn'>70x30x13 A-Frame Vertical Roof Barn</Link></ListItemPointer>
              <ListItemPointer><Link to='/90x140x16-commercial-clear-span'>90x140x16 Commercial Clear Span</Link></ListItemPointer>
              <ListItemPointer><Link to='/100x250x16-a-frame-warehouse'>100x250x16 A-Frame Warehouse</Link></ListItemPointer>
              <ListItemPointer><Link to='/20X30X7-a-frame-side-entry-utility-building'>20X30X7 A-Frame Side Entry Utility Building</Link></ListItemPointer>
              <ListItemPointer><Link to='/24x40x10-a-frame-garage'>24x40x10 A-Frame Garage</Link></ListItemPointer>
              <ListItemPointer><Link to='/34X65X12-a-frame-garage-lean-to'>34X65X12 A-Frame Garage Lean-To</Link></ListItemPointer>
              <ListItemPointer><Link to='/42x35x10-a-frame-garage-with-lean-to'>42x35x10 A-Frame Garage With Lean-To</Link></ListItemPointer>
              <ListItemPointer><Link to='/28X60X12-a-frame-vertical-roof-garage'>28X60X12 A-Frame Vertical Roof Garage</Link></ListItemPointer>
              <ListItemPointer><Link to='/50x64x18-clear-span-with-lean-to'>50x64x18 Clear Span With Lean-To</Link></ListItemPointer>
              <ListItemPointer><Link to='/100x100x16-warehouse'>100x100x16 Warehouse</Link></ListItemPointer>
              <ListItemPointer><Link to='/60X100X18-a-frame-warehouse'>60X100X18 A-Frame Warehouse</Link></ListItemPointer>
              <ListItemPointer><Link to='/60X70X20-commercial-clear-span-building'>60X70X20 Commercial Clear Span Building</Link></ListItemPointer>
              <ListItemPointer><Link to='/18x30x7-a-frame-side-entry-utility-building'>18x30x7 A-Frame Side Entry Utility Building</Link></ListItemPointer>
              <ListItemPointer><Link to='/18x50x11-a-frame-storage-unit'>18x50x11 A-Frame Storage Unit</Link></ListItemPointer>
            </ListPointers>
          {/* </SitemapItem> */}
        </SitemapGrid>
      </Container>
    </Section>
  </Layout>
)

export default SiteMapPage
